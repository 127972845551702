import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import "../common/styles/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Paper from "@mui/material/Paper"; //Used to divide the page into 2 columns and improve UI effect of background of an application
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useMsal } from "@azure/msal-react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import Button from "@mui/material/Button";
import Modal from "../common/modal/Modal";
import Alert from "@mui/joy/Alert";
import CircularProgress from "@mui/joy/CircularProgress";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Warning from "@mui/icons-material/Warning";


//Set theme properties for the screen reader
const Item = styled(Paper)(({ theme }) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
  },
  backgroundColor:
    theme.palette.mode === "dark" ? "#009de0 !important" : "#009de0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  boxShadow: "none",
  fontFamily: "GothamRounded-Book",
}));

const Metadata = ({
  nextStep,
  //closeFn = () => null,
  values,
  pbuDetail,
  vgrpDetail,
  projAsis,
  projMem,
  vgrpVal,
  pbuVal,
  radiovalue,
  setRadiovalue,
  searchVal,
  setSearchVal,
}) => {
  console.log("currnt URL: " + window.location.href);

  // Create variables for the screen reader
  const [allMetadata, setAllMetadata] = useState([]);
  const { instance, accounts } = useMsal();
  const [loadAllMdata, setLoadAllMetadata] = useState([]);
  const [selected, setSelected] = useState(null);
  const [searchValtemp, setsearchValtemp] = useState("");
  const loadAllMdatas = [];
  const [noProjfound, setnoProjfound] = useState("");
  const autoCompleteSearch = useRef();
  const [isInfo, setisInfo] = useState(false);  
  const [isInfoMsg, setInfoMsg] = useState("");
  const [isWarn, setisWarn] = useState(false);
  const [isWarnMsg, setisWarnMsg] = useState("");
  const [isWarnCRMIDDuplicates, setisWarnCRMIDDuplicates] = useState(false);
  const [isWarnCRMIDDuplicatesMsg, setisWarnCRMIDDuplicatesMsg] = useState("");
  const [isDuplicateCRMId, setisDuplicateCRMId] = useState(false);

  //api to get all CRM Opp related information
  const apiCRMOpp = process.env.REACT_APP_API_CRMOPPR;
  //api to get all CRM Proj related information
  const apiCRMProj = process.env.REACT_APP_API_CRMPROJ;
  //api to get all CRM related information
  const apiCRMSrch = process.env.REACT_APP_API_CRMSRCH;
  // api to get all CRM metadata
  const apiPBU = process.env.REACT_APP_API_GETPBU;
  //get project template from crm/maconomy based on pbu from DB
  const apiVrGrp = process.env.REACT_APP_API_VRGRP;
  // get all viwers group from DB
  const tokenType = process.env.REACT_APP_TOKENTYPE;
  // Message for Missing required Data;
  const reqMsg =process.env.REACT_APP_REQUIREDDATA_MSG;
  // Message for Not available Data
  const noDataMsg = process.env.REACT_APP_EMPTYDATA_MEG;
  //api to get all Mac related information
  const apiMacSrch = process.env.REACT_APP_API_MACSRCH;
  //api to get all Mac related information
  const apiMacMeta = process.env.REACT_APP_API_MACMETA;
  //api to check duplicate CRM Id project creation
  const apiDuplicateCRMIDCheck = process.env.REACT_APP_API_DUPLICATECRMIDCHECK;

  //Authentication options for API requests
  const accessTokenRequest = {
    scopes: [process.env.REACT_APP_SCOPES],
    account: accounts[0],
  };

  const loadProjTemplate = (getTemplate, pbuValue) => {
    if (pbuValue !== "") {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          // Call your API with token
          const headers = { Authorization: tokenType + accessToken };
          fetch(getTemplate, { headers })
            .then(function (response) {
              return response.json();
            })
            .then((pbuArrValue) => {
              pbuDetail.splice(0, pbuDetail.length); // Remove the existing pbuDetail array details
              for (
                let pbuCount = 0;
                pbuCount < pbuArrValue.data.length;
                pbuCount++
              ) {
                pbuDetail.push({
                  value: pbuArrValue.data[pbuCount].buildingBlockID,
                  label: pbuArrValue.data[pbuCount].buildingBlockName,
                });
              }
              pbuVal.push(pbuDetail[0]);
            });
          console.log("PBU", pbuDetail);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  //Load viewer group data from backend to the dropdown
  const loadViewerGroup = () => {
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        //Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        //Call your API with token
        const headers = { Authorization: tokenType + accessToken };
        fetch(apiVrGrp, { headers })
          .then(function (response) {
            return response.json();
          })
          .then((vgrpArrValue) => {
            vgrpDetail.splice(0, vgrpDetail.length); // Remove the existing vgrpDetail array details
            if (vgrpDetail.length <= vgrpArrValue.data.length - 1) {
              for (
                let vGrpCount = 0;
                vGrpCount < vgrpArrValue.data.length;
                vGrpCount++
              ) {
                //Formate viewer group array values
                vgrpDetail.push({
                  value: vgrpArrValue.data[vGrpCount].viewerGroupName,
                  label: vgrpArrValue.data[vGrpCount].viewerGroupName,
                  id: vgrpArrValue.data[vGrpCount].viewerGroupId
                });
              }
            }
          });
        console.log(vgrpDetail);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Functiolity for Next button click event
  const Continue = (e) => {
    e.preventDefault();
      //checks if the radiobutton and search fields are empty, if so display red border in the search bar
    if (radiovalue === "" && (searchVal === undefined || searchVal === "")) {
      autoCompleteSearch.current.style = "border:2px solid red";
    } else if (radiovalue === "CRM") {
              //if project name or search bar is empty then display red border
              if(values.prname === undefined ||
                  values.prname === "" ||
                  searchVal === undefined ||
                  searchVal === "")
              {
                autoCompleteSearch.current.style = "border:2px solid red";
              } 
              //if there is already filery id exists then display error msg to user
              else if (values.fileryId !== "") {                
                setisInfo(true);
                setInfoMsg(
                process.env.REACT_APP_WARNINGMSG_FILERYEXISTS +
              `Click<a href='`+ values.macfileryurl +`' target="_blank"> here </a> to open the project in ProjectWise Web.`
                );
              } 
              //if there is already filery project in progress then display error msg to user
              else if (isDuplicateCRMId) {
                  setisWarnCRMIDDuplicates(true);
                  setisWarnCRMIDDuplicatesMsg(
                  `<b>Processing...</b></br>` +
                  process.env.REACT_APP_WARNINGMSG_DUPLICATECRMIDCHECK
                  );
              } 
              //if the fields proj name, client, proj man,proj own,dept,pbu,geo and mar are missing then
              //display error msg to the user  
              else if (
                values.prname === reqMsg ||
                values.prname === undefined ||
                values.client === reqMsg ||
                values.client === undefined ||
                values.pjMan === reqMsg ||
                values.pjMan === undefined ||
                values.pjOwn === reqMsg ||
                values.pjOwn === undefined ||
                values.resDept === reqMsg ||
                values.resDept === undefined ||
                values.pbu === "" ||
                values.pbu === undefined ||
                values.geo === "" ||
                values.geo === undefined ||
                values.mar === "" ||
                values.mar === undefined
              ) {
                setisWarn(true);
                setisWarnMsg(process.env.REACT_APP_WARNINGMSG_MISSINGFIELDSCRM);
              } 
              //checking email id of PM or PO is available or display error msg
              else if (
                values.pjManDet.split("|")[1] === "" ||
                values.pjManDet.split("|")[1] === undefined ||
                values.pjManDet.split("|")[1] === null ||
                values.pjOwnDet.split("|")[1] === "" ||
                values.pjOwnDet.split("|")[1] === undefined ||
                values.pjOwnDet.split("|")[1] === null
              ) {
                setisWarn(true);
                setisWarnMsg(process.env.REACT_APP_WARNINGMSG_MISSINGEMAILVALIDATION);
              } 
              //checking submittal type if change order then display error msg to the user
              else if (
                values.submitaltype !== undefined &&
                values.submitaltype !== ""
              ) {
                  if (values.submitaltype.toLowerCase().indexOf("change order") > -1) {
                      setisWarn(true);
                      setisWarnMsg(process.env.REACT_APP_WARNINGMSG_SUBMITTALTYPE);
                  }
                  //else go to next page
                  else {
                      autoCompleteSearch.current.style = "border:0px";
                      nextStep(pbuDetail, vgrpDetail);
                 }
              } 
              //else go to next page
              else {
                autoCompleteSearch.current.style = "border:0px";
                nextStep(pbuDetail, vgrpDetail);
              }
    } else if (radiovalue === "Maconomy"){
             //if project name or search bar is empty then display red border
             if (values.prname === undefined ||
                  values.prname === "" ||
                  searchVal === undefined ||
                  searchVal === "") 
              {
                autoCompleteSearch.current.style = "border:2px solid red";
              } 
              //if there is already filery id exists then display error msg to user
              else if (values.fileryId !== "") {
                setisInfo(true);
                setInfoMsg(
                process.env.REACT_APP_WARNINGMSG_FILERYEXISTS +
                `&nbsp;Click<a href='`+ values.macfileryurl +`' target="_blank">&nbsp;here&nbsp;</a>to open the project in ProjectWise Web.`
                );
              }              
              //if there is already filery project in progress then display error msg to user for mac too
              else if (isDuplicateCRMId) {
                  setisWarnCRMIDDuplicates(true);
                  setisWarnCRMIDDuplicatesMsg(
                  `<b>Processing...</b></br>` +
                  process.env.REACT_APP_WARNINGMSG_DUPLICATECRMIDCHECK
                  );
              } 
              //if the fields proj name, client, proj man,proj own,dept,pbu,geo and mar are missing then
              //display error msg to the user 
              else if (
                values.prname === reqMsg ||
                values.prname === undefined ||
                values.client === reqMsg ||
                values.client === undefined ||
                values.pjMan === reqMsg ||
                values.pjMan === undefined ||
                values.pjOwn === reqMsg ||
                values.pjOwn === undefined ||
                values.resDept === reqMsg ||
                values.resDept === undefined ||
                values.pbu === "" ||
                values.pbu === undefined ||
                values.geo === "" ||
                values.geo === undefined ||
                values.mar === "" ||
                values.mar === undefined
              ) {
                setisWarn(true);
                setisWarnMsg(process.env.REACT_APP_WARNINGMSG_MISSINGFIELDSMAC);
              } 
              //checking email id of PM or PO is available or display error msg
              else if (
                values.pjManDet.split("|")[1] === "" ||
                values.pjManDet.split("|")[1] === undefined ||
                values.pjManDet.split("|")[1] === null ||
                values.pjOwnDet.split("|")[1] === "" ||
                values.pjOwnDet.split("|")[1] === undefined ||
                values.pjOwnDet.split("|")[1] === null
              ) {
                setisWarn(true);
                setisWarnMsg(process.env.REACT_APP_WARNINGMSG_MISSINGEMAILVALIDATION);
              } 
              //else go to next page
              else {
                autoCompleteSearch.current.style = "border:0px";
                nextStep(pbuDetail, vgrpDetail);
              }
    } 
    //else go to next page
    else {
      autoCompleteSearch.current.style = "border:0px";
      nextStep(pbuDetail, vgrpDetail);
    }
  };

  //Handle radio button click event
  const handleClickRadioBtn = (event) => {
    if (!!event.target.value) {
      setRadiovalue(event.target.value);
      clearFormFields();
      autoCompleteSearch.current.style = "border:0px";
    }
  };

  //Enable click in text and the radio button will enable
  const handleLabelClick = (value) => {
    setRadiovalue(value);
    clearFormFields();
    autoCompleteSearch.current.style = "border:0px";
  };

  //Clear all the form fields
  const clearFormFields = () => {
    values.prname = "";
    values.crmNo = "";
    values.macNo = "";
    values.client = "";
    values.pjMan = "";
    values.pjOwn = "";
    values.resDept = "";
    values.mar = "";
    values.geo = "";
    values.submitaltype = "";
    setSearchVal("");
    projAsis.splice(0, projAsis.length);
  };

  //Set all properties of the expressprojectnumber metadata
  const loadExpressProjectNumber = (url) => {
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        // Call your API with token
        const headers = { Authorization: tokenType + accessToken };
        fetch(url, { headers })
          .then(function (response) {
            return response.json();
          })
          .then((crmMetadata) => {
            console.log(crmMetadata);
            if (crmMetadata.data !== "") {
              values.prname =
                crmMetadata.data[0].obg_projectname !== null
                  ? crmMetadata.data[0].obg_projectname
                  : reqMsg;
              values.crmNo =
              crmMetadata.data[0].mac_expressprojectnumber !== null
              ? crmMetadata.data[0].mac_expressprojectnumber
              : crmMetadata.data[0].obg_OpportunityID.obg_opportunitynumber !== null
              ? crmMetadata.data[0].obg_OpportunityID.obg_opportunitynumber : noDataMsg;
              values.macNo =
                crmMetadata.data[0].mac_projectnumber !== null
                  ? crmMetadata.data[0].mac_projectnumber
                  : noDataMsg;
              values.client =
                crmMetadata.data[0].obg_DirectClientID !== null
                  ? crmMetadata.data[0].obg_DirectClientID.name
                  : reqMsg;
              values.pjMan =
                crmMetadata.data[0].obg_ProjectManagerID !== null
                  ? crmMetadata.data[0].obg_ProjectManagerID.obg_fullname
                  : reqMsg;
              values.pjManDet =
                crmMetadata.data[0].obg_ProjectManagerID !== null
                  ? crmMetadata.data[0].obg_ProjectManagerID.obg_fullname+"|"+crmMetadata.data[0].obg_ProjectManagerID.obg_emailaddress+"|"+crmMetadata.data[0].obg_ProjectManagerID.azureactivedirectoryobjectid
                  : "";
              values.pjOwn =
                crmMetadata.data[0].obg_ProjectOfficerID !== null
                  ? crmMetadata.data[0].obg_ProjectOfficerID.obg_fullname
                  : reqMsg;
              values.pjOwnDet =
                crmMetadata.data[0].obg_ProjectOfficerID !== null
                    ? crmMetadata.data[0].obg_ProjectOfficerID.obg_fullname+"|"+crmMetadata.data[0].obg_ProjectOfficerID.obg_emailaddress+"|"+crmMetadata.data[0].obg_ProjectOfficerID.azureactivedirectoryobjectid
                    : "";
              values.resDept =
                crmMetadata.data[0].obg_AuthorizingDivisionID !== null
                  ? (values.resDept =
                      crmMetadata.data[0].obg_AuthorizingDivisionID.obg_rdid +
                      " - " +
                      crmMetadata.data[0].obg_AuthorizingDivisionID.obg_name)
                  : reqMsg;
              values.mar =
                crmMetadata.data[0].obg_obgbusinessunit !== null
                  ? crmMetadata.data[0].obg_obgbusinessunit
                  : "";
              values.geo =
                crmMetadata.data[0].mac_geography_name !== null
                  ? crmMetadata.data[0].mac_geography_name
                  : "";
              values.fileryId =
                crmMetadata.data[0].mac_fileryid !== null
                  ? crmMetadata.data[0].mac_fileryid
                  : "";
              values.resDeptNo =
                crmMetadata.data[0].obg_AuthorizingDivisionID !== null
                  ? crmMetadata.data[0].obg_AuthorizingDivisionID.obg_rdid
                  : "";
              values.resDeptName =
                crmMetadata.data[0].obg_AuthorizingDivisionID !== null
                  ? crmMetadata.data[0].obg_AuthorizingDivisionID.obg_name
                  : "";
              values.resDeptCode =
                crmMetadata.data[0].obg_AuthorizingDivisionID !== null
                  ? crmMetadata.data[0].obg_AuthorizingDivisionID.obg_code
                  : "";
              values.pbu =
                crmMetadata.data[0].obg_AuthorizingDivisionID !== null
                  ? crmMetadata.data[0].obg_AuthorizingDivisionID.pbu
                  : "";
              pbuVal.splice(0, pbuVal.length);
              let getTemplate = apiPBU + values.pbu;
              loadProjTemplate(getTemplate, values.pbu);
              values.pbuDescription =
                crmMetadata.data[0].obg_AuthorizingDivisionID !== null
                  ? crmMetadata.data[0].obg_AuthorizingDivisionID
                      .mac_pbudescription
                  : "";
              values.clientID =
                crmMetadata.data[0].obg_DirectClientID !== null
                  ? crmMetadata.data[0].obg_DirectClientID.mac_clientnumber
                  : "";
              values.prnameEng =
                crmMetadata.data[0].mac_projectnameenglish !== null
                  ? crmMetadata.data[0].mac_projectnameenglish
                  : "";
              values.streamId =
                crmMetadata.data[0].mac_streamid !== null
                  ? crmMetadata.data[0].mac_streamid
                  : "";
              values.expectedProjectStartDate =
                crmMetadata.data[0].obg_startdate !== null
                  ? crmMetadata.data[0].obg_startdate
                  : null;
              values.expectedProjectEndDate = null;
              values.projectLevelRating =
                crmMetadata.data[0].mac_projectlevelrating_name !== null
                  ? crmMetadata.data[0].mac_projectlevelrating_name
                  : "";
              values.macfileryurl = crmMetadata.data[0].mac_fileryurl !== null
                  ? crmMetadata.data[0].mac_fileryurl
                  : "";
              values.prstage = crmMetadata.data[0].statecode_name !== null
                  ? crmMetadata.data[0].statecode_name
                  : "";
              values.projectCreatedFrom = "CPRO";  // Variable to find the project created from
              projAsis.splice(0,projAsis.length);
              if(crmMetadata.data[0].mac_ProjectAssistant !== null && radiovalue === "CRM")
                { 
                    projAsis.push({
                      value:
                      crmMetadata.data[0].mac_ProjectAssistant.obg_fullname +"|"+crmMetadata.data[0].mac_ProjectAssistant.obg_emailaddress+"|"+ crmMetadata.data[0].mac_ProjectAssistant.azureactivedirectoryobjectid,
                      label:
                      crmMetadata.data[0].mac_ProjectAssistant.obg_fullname +
                      " - " +
                      crmMetadata.data[0].mac_ProjectAssistant.obg_emailaddress,
                  });
                }
                            
              projMem.splice(0, projMem.length);
              vgrpVal.splice(0, vgrpVal.length);
              setAllMetadata(crmMetadata);
            } else {
              clearFormFields();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };

  //Set all the properties of the opportunity metadata
  const loadOpportunityMetadata = (url) => {
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        // Call your API with token
        const headers = { Authorization: tokenType + accessToken };
        fetch(url, { headers })
          .then(function (response) {
            return response.json();
          })
          .then((crmMetadata) => {
            console.log(crmMetadata);
            if (crmMetadata.data !== "") {
              values.prname =
                crmMetadata.data[0].name !== null
                  ? crmMetadata.data[0].name
                  : reqMsg;
              values.crmNo =
                crmMetadata.data[0].obg_opportunitynumber !== null
                  ? crmMetadata.data[0].obg_opportunitynumber
                  : noDataMsg;
              values.macNo =
                crmMetadata.data[0].mac_projectnumber !== null
                  ? crmMetadata.data[0].mac_projectnumber
                  : noDataMsg;
              values.client =
                crmMetadata.data[0].obg_ClientID !== null
                  ? crmMetadata.data[0].obg_ClientID.name
                  : reqMsg;
              values.pjMan =
                crmMetadata.data[0].obg_ProjectManagerID !== null
                  ? crmMetadata.data[0].obg_ProjectManagerID.obg_fullname
                  : reqMsg;
              values.pjManDet =
                crmMetadata.data[0].obg_ProjectManagerID !== null
                  ? crmMetadata.data[0].obg_ProjectManagerID.obg_fullname+"|"+crmMetadata.data[0].obg_ProjectManagerID.obg_emailaddress+"|"+crmMetadata.data[0].obg_ProjectManagerID.azureactivedirectoryobjectid
                  : "";
              projAsis.splice(0, projAsis.length);
              if (
                crmMetadata.data[0].owninguser !== null ||
                crmMetadata.data[0]._mac_tenderownerid_value !== null
              ) {
                if (crmMetadata.data[0].owninguser !== null) {
                  projAsis.push({
                    value: crmMetadata.data[0].owninguser.fullname+"|"+crmMetadata.data[0].owninguser.internalemailaddress+"|"+crmMetadata.data[0].owninguser.azureactivedirectoryobjectid,
                    label:
                      crmMetadata.data[0].owninguser.fullname +
                      " - " +
                      crmMetadata.data[0].owninguser.internalemailaddress,
                  });
                }
                if (crmMetadata.data[0]._mac_tenderownerid_value !== null) {
                  if (crmMetadata.data[0].owninguser !== null){
                    if(crmMetadata.data[0]._mac_tenderownerid_value.obg_emailaddress.toLowerCase()!==crmMetadata.data[0].owninguser.internalemailaddress.toLowerCase()) {
                        projAsis.push({
                            value:
                            crmMetadata.data[0]._mac_tenderownerid_value.obg_fullname+"|"+crmMetadata.data[0]._mac_tenderownerid_value.obg_emailaddress+"|"+crmMetadata.data[0]._mac_tenderownerid_value.azureactivedirectoryobjectid,
                            label:
                            crmMetadata.data[0]._mac_tenderownerid_value
                            .obg_fullname +
                            " - " +
                            crmMetadata.data[0]._mac_tenderownerid_value
                            .obg_emailaddress,
                        });
                    }
                }
                else 
                {
                        projAsis.push({
                            value:
                            crmMetadata.data[0]._mac_tenderownerid_value.obg_fullname+"|"+crmMetadata.data[0]._mac_tenderownerid_value.obg_emailaddress+"|"+crmMetadata.data[0]._mac_tenderownerid_value.azureactivedirectoryobjectid,
                            label:
                            crmMetadata.data[0]._mac_tenderownerid_value
                            .obg_fullname +
                            " - " +
                            crmMetadata.data[0]._mac_tenderownerid_value
                            .obg_emailaddress,
                        });
                }
                }
              }
              values.resDept =
                crmMetadata.data[0].obg_AuthorizingDivisionID !== null
                  ? crmMetadata.data[0].obg_AuthorizingDivisionID.obg_rdid +
                    " - " +
                    crmMetadata.data[0].obg_AuthorizingDivisionID.obg_name
                  : reqMsg;
              values.pjOwn =
                crmMetadata.data[0].obg_ProjectOfficerID !== null
                  ? crmMetadata.data[0].obg_ProjectOfficerID.obg_fullname
                  : reqMsg;
              values.pjOwnDet =
                crmMetadata.data[0].obg_ProjectOfficerID !== null
                  ? crmMetadata.data[0].obg_ProjectOfficerID.obg_fullname+"|"+crmMetadata.data[0].obg_ProjectOfficerID.obg_emailaddress+"|"+crmMetadata.data[0].obg_ProjectOfficerID.azureactivedirectoryobjectid
                  : "";
              values.mar =
                crmMetadata.data[0].obg_businessunit !== null
                  ? crmMetadata.data[0].obg_businessunit
                  : "";
              values.geo =
                crmMetadata.data[0].mac_geography_name !== null
                  ? crmMetadata.data[0].mac_geography_name
                  : "";
              values.pbu =
                crmMetadata.data[0].obg_AuthorizingDivisionID !== null
                  ? crmMetadata.data[0].obg_AuthorizingDivisionID.pbu
                  : "";
              pbuVal.splice(0, pbuVal.length);
              let getTemplate = apiPBU + values.pbu;
              loadProjTemplate(getTemplate, values.pbu);
              values.submitaltype =
                crmMetadata.data[0].obg_submittaltype_name !== null
                  ? crmMetadata.data[0].obg_submittaltype_name
                  : "";
              values.fileryId =
                crmMetadata.data[0].mac_fileryid !== null
                  ? crmMetadata.data[0].mac_fileryid
                  : "";
              values.resDeptNo =
                crmMetadata.data[0].obg_AuthorizingDivisionID !== null
                  ? crmMetadata.data[0].obg_AuthorizingDivisionID.obg_rdid
                  : "";
              values.resDeptName =
                crmMetadata.data[0].obg_AuthorizingDivisionID !== null
                  ? crmMetadata.data[0].obg_AuthorizingDivisionID.obg_name
                  : "";
              values.resDeptCode =
                crmMetadata.data[0].obg_AuthorizingDivisionID !== null
                  ? crmMetadata.data[0].obg_AuthorizingDivisionID.obg_code
                  : "";
              values.pbuDescription =
                crmMetadata.data[0].obg_AuthorizingDivisionID !== null
                  ? crmMetadata.data[0].obg_AuthorizingDivisionID
                      .mac_pbudescription
                  : "";
              values.clientID =
                crmMetadata.data[0].obg_ClientID !== null
                  ? crmMetadata.data[0].obg_ClientID.obg_crmclientid
                  : "";
              values.streamId =
                crmMetadata.data[0].mac_streamid !== null
                  ? crmMetadata.data[0].mac_streamid
                  : "";
              values.expectedProjectStartDate =
                  crmMetadata.data[0].obg_projectedstartdate !== null
                    ? crmMetadata.data[0].obg_projectedstartdate
                    : null;
              values.expectedProjectEndDate =
                  crmMetadata.data[0].mac_projectcompletedate !== null
                    ? crmMetadata.data[0].mac_projectcompletedate
                    : null;
              values.projectLevelRating =
                  crmMetadata.data[0].obg_piriskscore_name !== null
                    ? crmMetadata.data[0].obg_piriskscore_name
                    : "";
              values.macfileryurl = crmMetadata.data[0].mac_fileryurl !== null
                    ? crmMetadata.data[0].mac_fileryurl
                    : "";
              values.prstage = crmMetadata.data[0].statecode_name !== null
                    ? crmMetadata.data[0].statecode_name
                    : "";
              values.prnameEng = ""; //opportunity dont have project name english so passing in as empty string
              values.projectCreatedFrom = "COPP"; // Variable to find the project created from              
              projMem.splice(0, projMem.length);
              vgrpVal.splice(0, vgrpVal.length);
              setAllMetadata(crmMetadata);
            } else {
              clearFormFields();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };

  //Set all the properties of the maconomy metadata
  const loadMaconomyMetadata = (url) => {
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        // Call your API with token
        const headers = { Authorization: tokenType + accessToken };
        fetch(url, { headers })
          .then(function (response) {
            return response.json();
          })
          .then((macMetadata) => {
            console.log(macMetadata);
            if (macMetadata.data !== "") {
              values.prname =
              macMetadata.data[0].projectNameNativeLanguage !== null
                ? macMetadata.data[0].projectNameNativeLanguage
                : reqMsg;
            values.crmNo =
            macMetadata.data[0].opportunitynumber !== null
                  ? macMetadata.data[0].opportunitynumber
                  : noDataMsg;
            values.macNo =
            macMetadata.data[0].maconomyprojectnumber !== null
                ? macMetadata.data[0].maconomyprojectnumber
                : noDataMsg;
            values.client =
            macMetadata.data[0].client !== null
                ? macMetadata.data[0].client
                : reqMsg;
            values.pjMan =
            macMetadata.data[0].projectManager !== null
                ? macMetadata.data[0].projectManager
                : reqMsg;
            values.pjManDet =
            macMetadata.data[0].projectManager !== null
                  ? macMetadata.data[0].projectManager+"|"+macMetadata.data[0].projectManagerEmail+"|"+null
                  : "";
              values.pjOwn =
                macMetadata.data[0].projectOwner !== null
                  ? macMetadata.data[0].projectOwner
                  : reqMsg;
              values.pjOwnDet =
                macMetadata.data[0].projectOwner !== null
                  ? macMetadata.data[0].projectOwner +
                    "|" +
                    macMetadata.data[0].projectOwnerEmail +
                    "|" +
                    null
                  : "";
              values.resDept =
                macMetadata.data[0].responsibleDepartment !== null
                  ? macMetadata.data[0].responsibleDepartment
                  : reqMsg;
              values.mar =
                macMetadata.data[0].leadMarket !== null
                  ? macMetadata.data[0].leadMarket
                  : "";
              values.geo =
                macMetadata.data[0].leadGeography !== null
                  ? macMetadata.data[0].leadGeography
                  : "";
              values.fileryId =
                macMetadata.data[0].fileryID !== null
                  ? macMetadata.data[0].fileryID
                  : "";
              values.resDeptNo = macMetadata.data[0].departmentNo !== null
                ? macMetadata.data[0].departmentNo
                : "";
            values.resDeptName = macMetadata.data[0].responsibleDepartment !== null
                ? macMetadata.data[0].responsibleDepartment
                : "";
            values.resDeptCode = values.resDeptNo + "-" + values.resDeptName;
              values.pbu =
                macMetadata.data[0].pbu !== null ? macMetadata.data[0].pbu : "";
              values.pbuDescription =
                macMetadata.data[0].pbuDescription !== null
                  ? macMetadata.data[0].pbuDescription
                  : "";
                pbuVal.splice(0, pbuVal.length);
            let getTemplate = apiPBU + values.pbu;
            loadProjTemplate(getTemplate, values.pbu);
            values.submitaltype = "";
            values.resDeptName =
            macMetadata.data[0].responsibleDepartment !== null
                ?  macMetadata.data[0].responsibleDepartment 
                : "";
            values.clientID =
            macMetadata.data[0].clientID !== null
                ? macMetadata.data[0].clientID
                : "";
            values.prnameEng =
            macMetadata.data[0].projectNameEnglish !== null
                ? macMetadata.data[0].projectNameEnglish
                : "";
            values.streamId =
            macMetadata.data[0].streamID !== null
                ? macMetadata.data[0].streamID
                : "";
            values.expectedProjectStartDate =
            macMetadata.data[0].expectedprojectstartdate !== null
                ? macMetadata.data[0].expectedprojectstartdate
                : null;
            values.expectedProjectEndDate = 
            macMetadata.data[0].expectedenddate !== null
            ? macMetadata.data[0].expectedenddate
            : null;
            values.projectLevelRating =
            macMetadata.data[0].projectlevelrating !== null
                ? macMetadata.data[0].projectlevelrating
                : "";
            values.macfileryurl = macMetadata.data[0].fileryURL !== null
                ? macMetadata.data[0].fileryURL
                : "";
                values.projectCreatedFrom = "MACP";  // Variable to find the project created from
                values.prstage = "";
                projAsis.splice(0, projAsis.length);
                projMem.splice(0, projMem.length);
                vgrpVal.splice(0, vgrpVal.length);
                  setAllMetadata(macMetadata);
                } else {
                  clearFormFields();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          });
      };

  //Handle for auto complete event when user clicks search field
  useEffect(() => {
    if (loadAllMdata.length > 0) {
      if (searchVal.length <= 3) {
        setLoadAllMetadata([]);
      }
    }
  });
  //Function to load all metadata from action button CRM based on query string  parameters
  useEffect(() => {
    //Get all viewer group values
    loadViewerGroup();
    if (window.location.href.indexOf("crmNo") > -1) {
      let crmNofromURL = window.location.href
      .split("&crmNo=")[1]
      .split("&isProject=")[0];
    setRadiovalue("CRM");
    setSearchVal(crmNofromURL);
    setsearchValtemp(crmNofromURL);
    if (window.location.href.split("&isProject=")[1] === "Yes") {
      let url = apiCRMProj + crmNofromURL;
      loadExpressProjectNumber(url);
    } else {
      let url = apiCRMOpp + crmNofromURL;
      loadOpportunityMetadata(url);
    }
  }
    if (window.location.href.indexOf("macNo") > -1) {
      let macNofromURL = window.location.href
      .split("&macNo=")[1];
    setRadiovalue("Maconomy");
    setSearchVal(macNofromURL);
    setsearchValtemp(macNofromURL);
    let url = apiMacMeta + macNofromURL;
    loadMaconomyMetadata(url);
    }
  }, []);

  useEffect(() => {
    if (searchValtemp !== "") {
      setSearchVal(searchValtemp);

      if (radiovalue === "CRM") {
      ///Define the validation part for metadata
      if (
        values.prname === reqMsg ||
        values.prname === undefined ||
        values.client === reqMsg ||
        values.client === undefined ||
        values.pjMan === reqMsg ||
        values.pjMan === undefined ||
        values.pjOwn === reqMsg ||
        values.pjOwn === undefined ||
        values.resDept === reqMsg ||
        values.resDept === undefined ||
        values.pbu === "" ||
        values.pbu === undefined ||
        values.geo === "" ||
        values.geo === undefined ||
        values.mar === "" ||
        values.mar === undefined
      ) {
        setisWarn(true);
        setisWarnMsg(process.env.REACT_APP_WARNINGMSG_MISSINGFIELDSCRM);
      }
    }
    if (radiovalue === "Maconomy") {
      ///Define the validation part for metadata
      if (
        values.prname === reqMsg ||
        values.prname === undefined ||
        values.client === reqMsg ||
        values.client === undefined ||
        values.pjMan === reqMsg ||
        values.pjMan === undefined ||
        values.pjOwn === reqMsg ||
        values.pjOwn === undefined ||
        values.resDept === reqMsg ||
        values.resDept === undefined ||
        values.pbu === "" ||
        values.pbu === undefined ||
        values.geo === "" ||
        values.geo === undefined ||
        values.mar === "" ||
        values.mar === undefined
      ) {
        setisWarn(true);
        setisWarnMsg(process.env.REACT_APP_WARNINGMSG_MISSINGFIELDSMAC);
      }
    }
    }
  }, [allMetadata]);

  //Event handlers for search box click events
  const handleOnSelectSearch = (event, newValue) => {
    const [selected] = loadAllMdata?.filter((o) => o?.value === newValue);
    setSelected(selected || loadAllMdata[0]);
    setSearchVal("");
    autoCompleteSearch.current.style = "border:0px";
    if (newValue !== null) { 
      if(radiovalue === "CRM") {
      //Metadata from project
      setsearchValtemp(newValue.label.split(" - ")[0]);
      if (newValue.isProject) {
        let url = apiCRMProj + newValue.label.split(" - ")[0];
        loadExpressProjectNumber(url);
      }
      //Metadata from CRM
      else {
        let url = apiCRMOpp + newValue.label.split(" - ")[0];
        loadOpportunityMetadata(url);
      }
      
    }else{
      //Metadata from Mac
      setsearchValtemp(newValue.label.split(" - ")[0]);
      let url = apiMacMeta + newValue.label.split(" - ")[0];
      loadMaconomyMetadata(url);
    }
    duplicateCRMOrMAcIDCheck(apiDuplicateCRMIDCheck + newValue.label.split(" - ")[0]);
  }
  };

  //Hadle search
  const handleOnSearch = (e) => {
    autoCompleteSearch.current.style = "border:0px";
    if (e !== null) {
      if (e.type === "change" || e.type === "click") {
        if (e.target.value !== undefined) {
          setSearchVal(e.target.value);
          if (radiovalue === "CRM") {
            if (e.target.value.length >= 4) {
              setnoProjfound("Searching...");
              const urlCRM = apiCRMSrch + e.target.value;
              instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                  // Acquire token silent success
                  let accessToken = accessTokenResponse.accessToken;
                  // Call your API with token
                  const headers = { Authorization: tokenType + accessToken };
                  fetch(urlCRM, { headers })
                    .then(function (response) {
                      return response.json();
                    })
                    .then((drpMetadata) => {
                      drpMetadata.data.map((item, index) => {
                        if (!item.isProject) {
                          loadAllMdatas.push({
                            label:
                              item.opportunitynumber.toString() +
                              " - " +
                              item.oppname,
                            isProject: item.isProject,
                          });
                        } else {
                          loadAllMdatas.push({
                            label:
                            item.expressprojectnumber !== null
                            ? item.expressprojectnumber.toString() + " - " + item.proj_name
                            : item.opportunitynumber.toString() + " - " + item.proj_name,
                            isProject: item.isProject,
                          });
                        }
                      });
                      loadAllMdatas.sort((a, b) =>
                        a.label > b.label ? 1 : -1
                      );
                      if (loadAllMdatas.length === 0) {
                        setnoProjfound("No Projects Found");
                      } else {
                        setnoProjfound("");
                      }
                      setLoadAllMetadata(loadAllMdatas);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                });
            } else {
              setLoadAllMetadata([]);
            }
          }else{
            if (e.target.value.length >= 4) {
              setnoProjfound("Searching...");
              const urlMac = apiMacSrch + e.target.value;
              instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                  // Acquire token silent success
                  let accessToken = accessTokenResponse.accessToken;
                  // Call your API with token
                  const headers = { Authorization: tokenType + accessToken };
                  fetch(urlMac, { headers })
                    .then(function (response) {
                      return response.json();
                    })
                    .then((drpMetadata) => {
                      drpMetadata.data.map((item, index) => {                  
                          loadAllMdatas.push({
                            label:
                              item.maconomyprojectnumber.toString() +
                              " - " +
                              item.projectNameEnglish
                          });                  
                      });
                      loadAllMdatas.sort((a, b) =>
                        a.label > b.label ? 1 : -1
                      );
                      if (loadAllMdatas.length === 0) {
                        setnoProjfound("No Projects Found");
                      } else {
                        setnoProjfound("");
                      }
                      setLoadAllMetadata(loadAllMdatas);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                });
        } else {
          setLoadAllMetadata([]);
        }
      }
    } else {
      setLoadAllMetadata([]);
    }
      }
    }
  };

  //Check the CRM ID has already Filery project or in progress
  const duplicateCRMOrMAcIDCheck = (url) => {
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        // Call your API with token
        const headers = { Authorization: tokenType + accessToken };
        fetch(url, { headers })
          .then(function (response) {
            return response.json();
          })
          .then((duplicateCRMResp) => {
            console.log(duplicateCRMResp);
            if(duplicateCRMResp.data === "NoDataFound"){
              setisDuplicateCRMId(false);
            }
            else{
              if(duplicateCRMResp.data.projectWiseCreationStatus === 0 || duplicateCRMResp.data.projectWiseCreationStatus === 1){
                setisDuplicateCRMId(true);
              }
            } 
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };
   //handle close event handler for alert
  const handleClose = () => {
    setisWarn(false);
    setisWarnMsg("");
  };
  //handle close event handler for alert
  const handleCloseCRMCheck = () => {
    setisWarnCRMIDDuplicates(false);
    setisWarnCRMIDDuplicatesMsg("");
  };
   //handle close warning info for alert
   const handleCloseFileyProject = () =>{
    setisInfo(false);
    setInfoMsg("");
  }
  //Render UI elements fr Metadata elements
  return (
    <Box>
      <Grid container>
         <Grid item xs={12}>
          <Box style={{ paddingBottom: "2%"}}
            sx={{
              display: "flex",
              //justifyContent: "start",
              m: 1,   
              p: 1,
              fontSize: "25px",
              fontFamily: "GothamRounded-Medium !important",
            }}
          >
            {"Create a Filery project"}
          </Box>
        </Grid>
       {/* <div class="projTitle">Create a Filery project</div> */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              p: 1,
              m: 1,
              padding: "0px",
              margin: "0",
            }}
          >
            <Item style={{ paddingLeft: "0%", width: "45%", paddingTop: "2%" }}>
              <label style={{ fontWeight: "bold"}}>Get started</label><br/>
              <label>
                Start by typing a CRM number or Maconomy project number
              </label>
            </Item>
            <Item style={{ width: "50%", paddingTop: "1px" }}>
              <div>
                <FormControl>
                  {/* Radio button option */}
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={radiovalue}
                    onClick={handleClickRadioBtn}
                  >
                    <FormControlLabel
                      value="CRM"
                      control={<Radio sx={{ color: "white" }} />}
                      label="CRM"
                      onClick={() => handleLabelClick("CRM")}
                    />
                    <FormControlLabel
                      value="Maconomy"
                      control={<Radio sx={{ color: "white" }} />}
                      label="Maconomy"
                      onClick={() => handleLabelClick("Maconomy")}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                {/* Search field */}
                <Autocomplete
                  disablePortal
                  freeSolo={
                    searchVal.length && searchVal.length >= 4 ? false : true
                  }
                  id="search-auto"
                  ref={autoCompleteSearch}
                  noOptionsText={noProjfound}
                  onInputChange={handleOnSearch}
                  onChange={handleOnSelectSearch}
                  options={loadAllMdata}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    fontWeight: "bold",
                  }}
                  value={searchVal}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search" />
                  )}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.label, inputValue);
                    const parts = parse(option.label, matches);
                    return (
                      <li {...props}>
                        <div>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    );
                  }}
                />
              </div>

              {/* Metadata Fields and it's values */}
              <p></p>
              <div className="divStyle">
                <label className="metaDataFieldtxt">Project Name</label>
                <LockOutlinedIcon
                  style={{ color: "#e5dbdb8c" }}
                ></LockOutlinedIcon>
                <input
                  type="text"
                  value={values.prname}
                  className={
                    values.prname === reqMsg
                      ? "metaDataFieldNoValue"
                      : "metaDataFieldValue"
                  }
                  id="pjName"
                  disabled="disabled"
                  title={values.prname}
                ></input>
              </div>
              <div className="divStyle">
                <label className="metaDataFieldtxt">Client</label>
                <LockOutlinedIcon
                  style={{ color: "#e5dbdb8c" }}
                ></LockOutlinedIcon>
                <input
                  type="text"
                  value={values.client}
                  className={
                    values.client === reqMsg
                    ? "metaDataFieldNoValue"
                      : "metaDataFieldValue"
                  }
                  id="client"
                  disabled="disabled"
                  readonly
                  title={values.client}
                ></input>
              </div>
              <div className="divStyle">
                <label className="metaDataFieldtxt">
                  Responsible Department
                </label>
                <LockOutlinedIcon
                  style={{ color: "#e5dbdb8c" }}
                ></LockOutlinedIcon>
                <input
                  type="text"
                  value={values.resDept}
                  className={
                    values.resDept === reqMsg
                    ? "metaDataFieldNoValue"
                    : "metaDataFieldValue"
                  }
                  id="resDept"
                  disabled="disabled"
                  readonly
                  title={values.resDept}
                ></input>
              </div>
              <div className="divStyle">
                <label className="metaDataFieldtxt">Project Manager</label>
                <LockOutlinedIcon
                  style={{ color: "#e5dbdb8c" }}
                ></LockOutlinedIcon>
                <input
                  type="text"
                  value={values.pjMan}
                  className={
                    values.pjMan === reqMsg
                    ? "metaDataFieldNoValue"
                    : "metaDataFieldValue"
                  }
                  id="pjManager"
                  disabled="disabled"
                  readonly
                  title={values.pjMan}
                ></input>
              </div>
              <div className="divStyle">
                <label className="metaDataFieldtxt">Project Owner</label>
                <LockOutlinedIcon
                  style={{ color: "#e5dbdb8c" }}
                ></LockOutlinedIcon>
                <input
                  type="text"
                  value={values.pjOwn}
                  className={
                    values.pjOwn === reqMsg
                    ? "metaDataFieldNoValue"
                    : "metaDataFieldValue"
                  }
                  id="pjOwner"
                  disabled="disabled"
                  readonly
                  title={values.pjOwn}
                ></input>
              </div>
              <div className="divStyle">
                <label className="metaDataFieldtxt">CRM Number</label>
                <LockOutlinedIcon
                  style={{ color: "#e5dbdb8c" }}
                ></LockOutlinedIcon>
                <input
                  type="text"
                  className="metaDataFieldValue"
                  value={values.crmNo}
                  id="crmNo"
                  disabled="disabled"
                  readonly
                  title={values.crmNo}
                ></input>
              </div>
              <div className="divStyle">
                <label className="metaDataFieldtxt">
                  Maconomy Project Number
                </label>
                <LockOutlinedIcon
                  style={{ color: "#e5dbdb8c" }}
                ></LockOutlinedIcon>
                <input
                  type="text"
                  value={values.macNo}
                  className={
                    values.macNo === reqMsg
                    ? "metaDataFieldNoValue"
                    : "metaDataFieldValue"
                  }
                  id="macNo"
                  disabled="disabled"
                  readonly
                  title={values.macNo}
                ></input>
              </div>
              <div style={{ textAlign: "right" }}>
                <label style={{ fontWeight: "bold" }}>
                  Does any of the information look incorrect?
                </label>
                <label>
                  Go to CRM or Maconomy to adjust the information, then search
                  for the project again
                </label>
              </div>
            </Item>

          </Box>
          
          
        </Grid>
        <div className="metadata-container">	

<div className="button-container">	
  <div className="btnAlignment">	         
    <button className="btnDesign" id="btnNextInfo" onClick={Continue}>	
      Next	
    </button>	
  </div>	
</div>	
</div>
      </Grid>
      <Modal
        open={isWarn}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className="modal--mask">
          <div>
            <Stack spacing={2} sx={{ maxWidth: 400 }}>
              <Alert
                variant="soft"
                color="danger"
                invertedColors
                startDecorator={
                  <CircularProgress size="lg">
                    <Warning
                      variant="soft"
                      style={{ color: "#A51818 !important" }}
                    />
                  </CircularProgress>
                }
                sx={{ alignItems: "flex-start", "--Alert-gap": "1rem" }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography sx={{ mt: 1 }}>There was a problem.</Typography>
                  <br />
                  <p level="body3" style={{ textDecoration: "none" }}>
                    <p dangerouslySetInnerHTML={{ __html: isWarnMsg }}></p>
                  </p>

                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 1,
                    }}
                  >
                    <Button variant="soft" size="sm" style={{ border: "1px solid", borderRadius: "20px" }} onClick={handleClose}>
                      Okay
                    </Button>
                  </Box>
                </Box>
              </Alert>
            </Stack>
          </div>
        </div>
      </Modal>
      <Modal
        open={isWarnCRMIDDuplicates}
        onClose={handleCloseCRMCheck}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className="modal--mask">
          <div>
            <Stack spacing={2} sx={{ maxWidth: 400 }}>
              <Alert
                variant="soft"
                color="warning"
                invertedColors
                startDecorator={
                  <CircularProgress size="lg">
                    <Warning
                      variant="soft"
                      style={{ color: "#A51818 !important" }}
                    />
                  </CircularProgress>
                }
                sx={{ alignItems: "flex-start", "--Alert-gap": "1rem" }}
              >
                <Box sx={{ flex: 1 }}>
                  <br />
                  <p level="body3" style={{ textDecoration: "none" }}>
                    <p dangerouslySetInnerHTML={{ __html: isWarnCRMIDDuplicatesMsg }}></p>
                  </p>

                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 1,
                    }}
                  >
                    <Button variant="soft" size="sm" style={{ border: "1px solid", borderRadius: "20px" }} onClick={handleCloseCRMCheck}>
                      Okay
                    </Button>
                  </Box>
                </Box>
              </Alert>
            </Stack>
          </div>
        </div>
      </Modal>
      <Modal
        open={isInfo}
        onClose={handleCloseFileyProject}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className="modal--mask">
          <div>
            <Stack spacing={2} sx={{ maxWidth: 400 }}>
              <Alert
                variant="soft"
                color="danger"
                invertedColors
                startDecorator={null} // Remove the warning icon
                sx={{
                  alignItems: "flex-start",
                  "--Alert-gap": "1rem",
                  backgroundColor: "white", // Set background to white
                  color: "black", // Set text color to black
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography sx={{ mt: 1, color: "black" }}>
                  A Filery Project Location already exists.
                  </Typography>
                  <br />
                  <p
                    level="body3"
                    style={{
                      textDecoration: "none",
                      color: "black", // Ensure text is black
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: isInfoMsg }}
                    ></span>
                  </p>

                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 1,
                    }}
                  >
                    <Button
                      variant="soft"
                      size="sm"
                      sx={{
                        border: "1px solid",
                        borderRadius: "20px",
                        color: "black", // Button text color
                        borderColor: "black", // Button border color
                      }}
                      onClick={handleCloseFileyProject}
                    >
                      Close
                    </Button>
                  </Box>
                </Box>
              </Alert>
            </Stack>
          </div>
        </div>
      </Modal>
    </Box>
  );
};
export default Metadata;
